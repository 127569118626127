import React from "react"
import { Link } from "gatsby"

class InfoCards extends React.Component {
  render() {
    const data = this.props.data

    return (
      <div
        className="uk-grid uk-grid-small uk-grid-match uk-child-width-1 uk-child-width-1-2@m uk-margin-large-bottom"
        uk-grid=""
      >
        {data.map((card) => (
          <div key={card.title}>
            <div
              key={card.title}
              className={
                "uk-card uk-card-" +
                card.size +
                " uk-card-body uk-card-default uk-flex uk-flex-top"
              }
            >
              <div>
                <h4 className="uk-text-bold">{card.title}</h4>
                <p className="uk-text-muted">{card.content}</p>
                {!!card.stores ? (
                  <div className="uk-flex uk-flex-wrap uk-flex-middle">
                    <p className="uk-width-1-1 uk-text-muted">
                      Find your nearest stores:
                    </p>
                    {card.stores.map((store, i) => (
                      <a
                        href={store.link}
                        key={i}
                        target="_blank"
                        className="uk-margin-right uk-margin-small-bottom"
                      >
                        <img
                          src={store.image.url}
                          alt={store.image.title}
                          style={{ maxWidth: "120px", height: "auto" }}
                        />
                      </a>
                    ))}
                  </div>
                ) : null}
                {!!card.link ? (
                  <Link
                    to={card.link.url}
                    className="uk-button uk-button-primary uk-border-pill"
                  >
                    {!!card.link.text ? card.link.text : "Learn more"}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default InfoCards
