import React from "react"
import { graphql } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import UserForm from "../components/UserForm"
import InfoCards from "../components/pages/InfoCards"

// Data
import content from "../pages/static-pages/find-an-installer.yaml"

class Index extends React.Component {
  state = { sliderOptions: "" }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth

      if (x >= 640) {
        this.setState({ sliderOptions: "draggable: false; sets: true;" })
      } else {
        this.setState({ sliderOptions: "center: true;" })
      }
    }
  }

  render() {
    const options = []
    const products = this.props.data.allProductsYaml.edges
    products.forEach((product) => {
      options.push(
        <option value={product.node.id} key={product.node.id}>
          {product.node.title}
        </option>
      )
    })

    const breadcrumbs = [
      {
        label: content.title,
        path: "/request-an-installer/",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <Breadcrumbs items={breadcrumbs} absolute={true} />

        {/* Feature */}
        <div
          className="feature-banner find-an-installer uk-section uk-background-cover"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-container uk-margin-large-top">
            <div className="uk-width-1-2@s uk-width-2-5@m">
              <h1 className="uk-margin-small-top uk-text-global-family uk-h2 uk-text-bold">
                {content.feature.title}
              </h1>
              <div
                className="uk-text-p-large uk-text-primary"
                dangerouslySetInnerHTML={{
                  __html: content.feature.tagline,
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* Steps & Form */}
        <div className="uk-section uk-section-default">
          <div className="uk-container">
            <div className="uk-width-2-3@l uk-margin-auto uk-margin-large-bottom uk-text-center">
              <h2 className="uk-text-bold">How we help</h2>
              <div
                className="uk-position-relative uk-visible-toggle uk-margin-medium-top"
                tabIndex="-1"
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items">
                  {content.stepsContent.steps.map((step, i) => (
                    <div
                      className="uk-width-3-5 uk-width-1-2@s uk-width-1-4@m"
                      key={i}
                    >
                      <span
                        className="uk-text-primary"
                        uk-icon={
                          "icon: " +
                          step.icon.type +
                          "; ratio: " +
                          step.icon.ratio +
                          ";"
                        }
                      ></span>
                      <p className="uk-text-primary uk-text-small uk-width-4-5 uk-width-3-5@m uk-width-4-5@l uk-margin-auto">
                        {step.title}
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
              </div>
            </div>

            {/* Form */}
            <h5 className="uk-width-4-5@l uk-margin-remove-top uk-margin-auto uk-text-center  ">
              {content.stepsContent.content}
            </h5>
            <UserForm />
          </div>
        </div>
        <div className="uk-section uk-section-secondary uk-section-small uk-section-default">
          <div className="uk-container">
            <InfoCards data={content.cards} />
          </div>
        </div>

        <div className="uk-section uk-section-secondary uk-section-small uk-section-default">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query SearchQuery {
    allProductsYaml(limit: 1000) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
        }
      }
    }
  }
`
